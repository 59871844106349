export default {
  metadataSelfTests: null,
  metadataPersonalTests: null,
  variantsPersonalReport: null,
  metadataTest360: null,
  metadataBySpec: null,
  historyPulseTest360: null,
  userTest360: null,
  commentsTest360: null,
  approversTest360: null,
  descriptionTest360: null,
  questions: null,
  urlPersonalTest: null,
  linkPersonalReport: null,
  userSurveys: {},
  userTests: {},
  userFeedbacks: {},
  isSubmitting: false,
  isSavingResults: false,
  isLoading: false,
  isLoadingDescriptionTest360: false,
  errors: null,
  validationErrors: null,
};
