import axios from '@/api/axios';

const getMetadataSelfTests = (userId) => axios.get(`/selftest/${userId}/specialization`);

const getMetadataBySpec = (userId, specId, test360Id) =>
  axios.get(
    `/selftest/${userId}/specialization/${specId}${test360Id ? `?testing_360_id=${test360Id}` : ''}`
  );

const getMetadataTest360 = (test360Id) => axios.get(`/selftest/test360/${test360Id}/params`);

const getHistoryPulseTest360 = (test360Id) =>
  axios.get(`/selftest/test360/${test360Id}/pulse_history`);

const getUserTest360 = (test360Id) => axios.get(`/selftest/test360/${test360Id}`);

const getCommentsTest360 = (test360Id) => axios.get(`/selftest/test360/${test360Id}/comments`);

const getApproversTest360 = (test360Id) => axios.get(`/selftest/test360/${test360Id}/approval`);

const getDescriptionTest360 = (test360Id) =>
  axios.get(`/selftest/test360/${test360Id}/description`);

const getQuestions = (userId, skillId, specId, typeId, blockId, testing360Id) =>
  axios.get(
    `/selftest/${userId}/questions/${skillId}?specId=${specId}&typeId=${typeId}&blockId=${blockId}${
      testing360Id ? `&testing_360_id=${testing360Id}` : ''
    }`
  );

const getMetadataPersonalTests = (userId) => axios.get(`/test/${userId}/personal`);

const getUrlPersonalTest = (userId, testId) => axios.get(`/test/${userId}/start/${testId}`);

const getLinkPersonalReport = (userId, sessionUid) =>
  axios.get(`/selftest/${userId}/personal/${sessionUid}/get_report_link`);

const getVariantsPersonalReport = (userId, sessionUid) =>
  axios.get(`/selftest/${userId}/personal/${sessionUid}/variants`);

const exportPersonalReport = (userId, sessionUid, variantId) =>
  axios.get(
    `/selftest/${userId}/personal/${sessionUid}/export${variantId ? `?variant=${variantId}` : ''}`,
    {
      responseType: 'blob',
    }
  );

const getUserSurveys = (isTest = false, surveyType) =>
  axios.get(`/user/surveys?survey_type=${isTest ? 'test' : surveyType ? surveyType : 'survey'}`);

const saveAnswer = (userId, bpId, data) => axios.post(`/selftest/${userId}/question/${bpId}`, data);

const resetAnswers = (userId, specId, blockId) =>
  axios.post(`/selftest/${userId}/reset/${specId}/${blockId}`);

const saveResults = (userId, specId, blockId, data, completion) =>
  axios.post(
    `/selftest/${userId}/save/${specId}/${blockId}${
      typeof completion === 'boolean' ? `?completion=${completion}` : ''
    }`,
    data
  );

const createReport = (userId, specCode, skillType) =>
  axios.post(`/selftest/${userId}/fix/${specCode}/${skillType}`);

const approveTest360 = (test360Id, data) =>
  axios.post(`/selftest/test360/${test360Id}/approval`, data);

const setDescriptionTest360 = (test360Id, data) =>
  axios.post(`/selftest/test360/${test360Id}/description`, data);

export default {
  getMetadataSelfTests,
  getMetadataTest360,
  getMetadataBySpec,

  getHistoryPulseTest360,
  getUserTest360,
  getCommentsTest360,
  getApproversTest360,
  getDescriptionTest360,
  getQuestions,

  getMetadataPersonalTests,
  getUrlPersonalTest,
  getLinkPersonalReport,
  getVariantsPersonalReport,
  exportPersonalReport,

  getUserSurveys,

  saveAnswer,
  resetAnswers,
  saveResults,
  createReport,
  approveTest360,
  setDescriptionTest360,
};
