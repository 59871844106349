export const mutationTypes = {
  getMetadataSelfTestsStart: '[testing] get metadata self tests start',
  getMetadataSelfTestsSuccess: '[testing] get metadata self tests success',
  getMetadataSelfTestsFailure: '[testing] get metadata self tests failure',

  getMetadataTest360Start: '[testing] get metadata test 360 start',
  getMetadataTest360Success: '[testing] get metadata test 360 success',
  getMetadataTest360Failure: '[testing] get metadata test 360 failure',

  getMetadataBySpecStart: '[testing] get metadata test by spec start',
  getMetadataBySpecSuccess: '[testing] get metadata test by spec success',
  getMetadataBySpecFailure: '[testing] get metadata test by spec failure',

  getHistoryPulseTest360Start: '[testing] get history pulse test 360 start',
  getHistoryPulseTest360Success: '[testing] get history pulse test 360 success',
  getHistoryPulseTest360Failure: '[testing] get history pulse test 360 failure',

  getUserTest360Start: '[testing] get user test 360 start',
  getUserTest360Success: '[testing] get user test 360 success',
  getUserTest360Failure: '[testing] get user test 360 failure',

  getCommentsTest360Start: '[testing] get comments test 360 start',
  getCommentsTest360Success: '[testing] get comments test 360 success',
  getCommentsTest360Failure: '[testing] get comments test 360 failure',

  getApproversTest360Start: '[testing] get approvers test 360 start',
  getApproversTest360Success: '[testing] get approvers test 360 success',
  getApproversTest360Failure: '[testing] get approvers test 360 failure',

  getDescriptionTest360Start: '[testing] get description test 360 start',
  getDescriptionTest360Clear: '[testing] get description test 360 clear',
  getDescriptionTest360Success: '[testing] get description test 360 success',
  getDescriptionTest360Failure: '[testing] get description test 360 failure',

  getQuestionsStart: '[testing] get questions start',
  getQuestionsSuccess: '[testing] get questions success',
  getQuestionsFailure: '[testing] get questions failure',

  getMetadataPersonalTestsStart: '[testing] get metadata personal tests start',
  getMetadataPersonalTestsSuccess: '[testing] get metadata personal tests success',
  getMetadataPersonalTestsFailure: '[testing] get metadata personal tests failure',

  getUrlPersonalTestStart: '[testing] get url for personal test start',
  getUrlPersonalTestSuccess: '[testing] get url for personal test success',
  getUrlPersonalTestFailure: '[testing] get url for personal test failure',

  getLinkPersonalReportStart: '[testing] get link to the personal report start',
  getLinkPersonalReportSuccess: '[testing] get link to the personal report success',
  getLinkPersonalReportFailure: '[testing] get link to the personal report failure',

  getVariantsPersonalReportStart: '[testing] get variants personal report start',
  getVariantsPersonalReportSuccess: '[testing] get variants personal report success',
  getVariantsPersonalReportFailure: '[testing] get variants personal report failure',

  exportPersonalReportStart: '[testing] export personal report start',
  exportPersonalReportSuccess: '[testing] export personal report success',
  exportPersonalReportFailure: '[testing] export personal report failure',

  getUserSurveysStart: '[testing] get user surveys start',
  getUserSurveysSuccess: '[testing] get user surveys success',
  getUserSurveysFailure: '[testing] get user surveys failure',

  getUserTestsStart: '[testing] get user tests start',
  getUserTestsSuccess: '[testing] get user tests success',
  getUserTestsFailure: '[testing] get user tests failure',

  getUserFeedbacksStart: '[testing] get user feedbacks start',
  getUserFeedbacksSuccess: '[testing] get user feedbacks success',
  getUserFeedbacksFailure: '[testing] get user feedbacks failure',

  saveAnswerStart: '[testing] save answer start',
  saveAnswerSuccess: '[testing] save answer success',
  saveAnswerFailure: '[testing] save answer failure',

  resetAnswersStart: '[testing] reset answers start',
  resetAnswersSuccess: '[testing] reset answers success',
  resetAnswersFailure: '[testing] reset answers failure',

  saveResultsStart: '[testing] save results start',
  saveResultsSuccess: '[testing] save results success',
  saveResultsFailure: '[testing] save results failure',

  createReportStart: '[testing] create report start',
  createReportSuccess: '[testing] create report success',
  createReportFailure: '[testing] create report failure',

  approveTest360Start: '[testing] approve test 360 start',
  approveTest360Success: '[testing] approve test 360 success',
  approveTest360Failure: '[testing] approve test 360 failure',

  setDescriptionTest360Start: '[testing] set description test 360 start',
  setDescriptionTest360Success: '[testing] set description test 360 success',
  setDescriptionTest360Failure: '[testing] set description test 360 failure',
};

const mutations = {
  [mutationTypes.getMetadataSelfTestsStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getMetadataSelfTestsSuccess](state, payload) {
    state.isLoading = false;
    state.metadataSelfTests = payload;
  },
  [mutationTypes.getMetadataSelfTestsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getMetadataTest360Start](state) {
    state.isLoading = true;
  },
  [mutationTypes.getMetadataTest360Success](state, payload) {
    state.isLoading = false;
    state.metadataTest360 = payload;
  },
  [mutationTypes.getMetadataTest360Failure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getMetadataBySpecStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getMetadataBySpecSuccess](state, payload) {
    state.isLoading = false;
    state.metadataBySpec = payload;
  },
  [mutationTypes.getMetadataBySpecFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getHistoryPulseTest360Start](state) {
    state.isLoading = true;
  },
  [mutationTypes.getHistoryPulseTest360Success](state, payload) {
    state.isLoading = false;
    state.historyPulseTest360 = payload;
  },
  [mutationTypes.getHistoryPulseTest360Failure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getUserTest360Start](state) {
    state.isLoading = true;
  },
  [mutationTypes.getUserTest360Success](state, payload) {
    state.isLoading = false;
    state.userTest360 = payload;
  },
  [mutationTypes.getUserTest360Failure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getCommentsTest360Start](state) {
    state.isLoading = true;
  },
  [mutationTypes.getCommentsTest360Success](state, payload) {
    state.isLoading = false;
    state.commentsTest360 = payload;
  },
  [mutationTypes.getCommentsTest360Failure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getApproversTest360Start](state) {
    state.isLoading = true;
    state.approversTest360 = null;
  },
  [mutationTypes.getApproversTest360Success](state, payload) {
    state.isLoading = false;
    state.approversTest360 = payload;
  },
  [mutationTypes.getApproversTest360Failure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getDescriptionTest360Start](state) {
    state.isLoadingDescriptionTest360 = true;
    state.descriptionTest360 = null;
  },
  [mutationTypes.getDescriptionTest360Clear](state) {
    state.descriptionTest360 = null;
  },
  [mutationTypes.getDescriptionTest360Success](state, payload) {
    state.isLoadingDescriptionTest360 = false;
    state.descriptionTest360 = payload;
  },
  [mutationTypes.getDescriptionTest360Failure](state) {
    state.isLoadingDescriptionTest360 = false;
  },

  [mutationTypes.getQuestionsStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getQuestionsSuccess](state, payload) {
    state.isLoading = false;
    state.questions = payload;
  },
  [mutationTypes.getQuestionsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getMetadataPersonalTestsStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getMetadataPersonalTestsSuccess](state, payload) {
    state.isLoading = false;
    state.metadataPersonalTests = payload;
  },
  [mutationTypes.getMetadataPersonalTestsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getUrlPersonalTestStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getUrlPersonalTestSuccess](state, payload) {
    state.isLoading = false;
    state.urlPersonalTest = payload;
  },
  [mutationTypes.getUrlPersonalTestFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getLinkPersonalReportStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getLinkPersonalReportSuccess](state, payload) {
    state.isLoading = false;
    state.linkPersonalReport = payload;
  },
  [mutationTypes.getLinkPersonalReportFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getVariantsPersonalReportStart](state) {
    state.isLoading = true;
    state.variantsPersonalReport = null;
  },
  [mutationTypes.getVariantsPersonalReportSuccess](state, payload) {
    state.isLoading = false;
    state.variantsPersonalReport = payload;
  },
  [mutationTypes.getVariantsPersonalReportFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.exportPersonalReportStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.exportPersonalReportSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.exportPersonalReportFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getUserSurveysStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getUserSurveysSuccess](state, payload) {
    state.isLoading = false;
    state.userSurveys = payload;
  },
  [mutationTypes.getUserSurveysFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getUserTestsStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getUserTestsSuccess](state, payload) {
    state.isLoading = false;
    state.userTests = payload;
  },
  [mutationTypes.getUserTestsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getUserFeedbacksStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getUserFeedbacksSuccess](state, payload) {
    state.isLoading = false;
    state.userFeedbacks = payload;
  },
  [mutationTypes.getUserFeedbacksFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.saveAnswerStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.saveAnswerSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.saveAnswerFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.resetAnswersStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.resetAnswersSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.resetAnswersFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.saveResultsStart](state) {
    state.isSubmitting = true;
    state.isSavingResults = true;
    state.validationErrors = null;
  },
  [mutationTypes.saveResultsSuccess](state) {
    state.isSubmitting = false;
    state.isSavingResults = false;
  },
  [mutationTypes.saveResultsFailure](state, payload) {
    state.isSubmitting = false;
    state.isSavingResults = false;
    state.validationErrors = payload;
  },

  [mutationTypes.createReportStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createReportSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createReportFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.approveTest360Start](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.approveTest360Success](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.approveTest360Failure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.setDescriptionTest360Start](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.setDescriptionTest360Success](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.setDescriptionTest360Failure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },
};

export default mutations;
