<template>
  <div class="app-main-container" :style="{'background-color': 'transparent'}">
    <v-main>
      <v-snackbar v-model="v.alert.visible" @input="closeAlert">
        <p v-for="(message, index) in v.alert.messages" :key="index">{{ message }}</p>
        <template #action="{attrs}">
          <v-btn color="blue" icon v-bind="attrs" @click="closeAlert">
            <v-icon v-text="'mdi-close'"></v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-row class="auth-layout p-0" dense>
        <v-col class="auth-layout__left d-none d-md-flex p-0">
          <app-auth-left-info />
        </v-col>

        <v-col class="p-0">
          <v-row no-gutters justify="center" style="height: 100%" align-content="center">
            <div>
              <v-row v-if="$useCustomButton" class="px-4 py-8" style="max-width: 600px">
                <app-title weight="medium" class="text-center">
                  {{ $authDescription }}
                </app-title>
              </v-row>

              <v-row no-gutters>
                <transition name="fade" mode="out-in">
                  <router-view
                    ref="child"
                    @on-sso-auth-click="$emit('on-go-sso-click')"
                  ></router-view>
                </transition>
              </v-row>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </v-main>
  </div>
</template>

<script>
import {themeMixin} from '@/mixins/theme';
import {closeAlert} from '@/helpers/utils';
import variables from '@/helpers/variables';

import AppAuthLeftInfo from '@/components/common/AuthLeftInfo';

export default {
  name: 'AppAuthView',
  components: {
    AppAuthLeftInfo,
  },
  mixins: [themeMixin],
  data() {
    return {
      closeAlert,
      v: variables,
    };
  },
  computed: {
    styles() {
      const {isMobile} = this;

      return isMobile ? '' : 'height: 100%';
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  created() {
    this.setCustomFont();
    this.setCustomTheme();
    this.addClassActiveTheme();
  },
};
</script>

<style lang="scss" scoped>
.auth-layout {
  &__left {
    height: 100%;
  }
  &::v-deep .auth-module-login-form,
  &::v-deep .auth-module-forgot-form {
    .v-btn:not(.v-btn--outlined).primary {
      color: var(--v-primary-contrast-base) !important;
    }
  }

  @media (max-width: 1024px) {
    &::v-deep .auth-module-login-form__login-row {
      flex-direction: column;
      align-items: center;
      .auth-module-login-form__login-button {
        margin: 5px 0 !important;
        min-width: 100% !important;
        width: 100% !important;
      }
    }
  }
}
</style>
